<template lang="pug">
b-collapse.panel.search-section.k-form-container-admin-search(
  :open.sync="isFormOpen"
)
  .panel-heading(
    v-if="$slots['header']"
    slot="trigger"
  )
    slot(name="header")
    template(v-if="title")
      | {{ title }}
  .panel-block
    slot(name="body")
  .panel-footer
    slot(name="footer")
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  title: { type: String, requird: false }
  // subtitle: { type: String, requird: false }
})

const isFormOpen = ref(true)
</script>

<style lang="sass">
@use '@shared/css/abstracts/size'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/color'

.k-form-container-admin-search
  .panel-block
    padding-top: size.$gap
    padding-bottom: size.$gap
    .columns
      width: 100%
  .panel-footer
    padding: size.$gap * 0.5
    display: flex
    justify-content: flex-end
    gap: size.$gap * .5
</style>
